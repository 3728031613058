<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      'New Contract': 'Novo Contrato',
      'Request': 'Solicitar',
    },
    es: {
      'New Contract': 'Nuevo Contrato',
      'Request': 'Solicitar',
    }
  },
  components: { Layout },
  data() {
    return {
      contract: {
        loading: true,

        id: this.$route.params.id,
        code: '-',
        status: '',
        sign: '',
        date: {
          start: '',
          end: '',
        },
        type: '-',
        period: '',
        value: '0.00',
        pdf: '',
      },

      fine: {
        days: '0',
        value: '0.00',
      },

      closed: {
        loading: false,
        status: true,
      },

      alert: {
        type: '',
        message: '',
        params: [],
      },
    };
  },
  methods: {
    getContract() {
      api
        .get('contract/' + this.contract.id)
        .then((response) => {
          if (response.data.status == 'success') {
            this.contract = response.data.contract
            this.contract.loading = false

            this.getFine()
          } else {
            this.$router.push('/contracts');
          }
        })
    },
    getFine() {
      api
        .get('contract/' + this.contract.id + '/fine')
        .then((response) => {
          if (response.data.status == 'success') {
            this.fine = response.data.fine
          }
        })
    },
    requestClose() {
      this.closed.loading = true

      api
        .post('contract/' + this.contract.id + '/close')
        .then((response) => {
          if (response.data.status == 'success') {
            this.closed.loading = false
            this.closed.status = true

            this.$router.push('/contracts/' + this.contract.id);
            this.$noty.success('O contrato foi encerrado com sucesso.')
          }
        })
    }
  },
  mounted() {
    this.getContract()
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Cancelamento de Contrato') }}</h4>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-md-6 col-xl-4">
        <div class="card">
          <div class="card-body">
            <h5 class="mb-4">Você está solicitando o encerramento do contrato e retirada do capital investido.</h5>
            <div class="my-2 d-flex justify-content-between">
              <div class="text-right border-right">Código do contrato</div>
              <div class="text-left">{{ contract.code }}</div>
            </div>
            <hr class="m-0">
            <div class="my-2 d-flex justify-content-between">
              <div class="text-right border-right">Valor do aporte</div>
              <div class="text-left">{{ contract.value | currency }}</div>
            </div>
            <hr class="m-0">
            <div class="my-2 d-flex justify-content-between">
              <div class="text-right border-right">Período contratado</div>
              <div class="text-left">{{ contract.period }} meses</div>
            </div>
            <hr class="m-0">
            <div class="my-2 d-flex justify-content-between">
              <div class="text-right border-right">Período utilizado</div>
              <div class="text-left">{{ fine.days }} dias</div>
            </div>
            <hr class="m-0">
            <div class="my-2 d-flex justify-content-between">
              <div class="text-right border-right">Multa</div>
              <div class="text-left">{{ fine.value | currency }}</div>
            </div>
            <hr class="m-0">
            <div class="my-2 d-flex justify-content-between">
              <div class="text-right border-right">Saldo à receber</div>
              <div class="text-left">{{ contract.value - fine.value | currency }}</div>
            </div>
            <div class="mt-5 mb-3 text-center">
              <b-button :disabled="contract.loading || closed.loading" size="lg" variant="default" @click="requestClose()">
                {{ t('Solicitar') }}
                <b-spinner v-if="closed.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.contract {
  border-left: solid 5px #090a09;
}
.contract-total {
  line-height: 14px;
}
.contract-code {
  line-height: 14px;
}
.divisor {
  position: relative;
  text-align: center;
}
.divisor span {
  display: inline-block;
  padding: 0 5px;
  background: #fff;
  font-size: 10px;
  letter-spacing: 2px;
  color: #888;
}
.divisor:before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 50%;
  height: 1px;
  background: #ddd;
}
</style>